import Auth from "@/components/Common/Auth/Auth.vue";
import SignUpDetail from "@/components/Common/Auth/SignUpDetail.vue";
import Empty from "@/views/Empty.vue";

export default {
  path: "",
  name: "auth",
  component: Empty,
  children: [
    {
      props: true,
      path: "signIn",
      name: "signIn",
      component: Auth
    },
    {
      path: "signUp",
      children: [
        {
          path: "",
          name: "signUp",
          component: Auth
        },
        {
          path: "profile",
          name: "signUpProfile",
          component: SignUpDetail
        }
        // {
        // path: "complete",
        // name: "signUpComplete",
        // component: SignUpDetail
        // }
      ]
    },
    {
      path: "reset",
      name: "reset",
      component: Auth
    }
  ]
};
