import { RootState } from "@core/store/root-state";
import { ActionTree, MutationTree, Module, ModuleTree } from "vuex";
import { ComplaintsState } from "@core/store/types/admin/complaints";
import {
  CLOSE_TRAFFIC_COMPLAINT,
  GET_COMPLAINTS,
  SET_EMPTY,
  UPDATE_COMPLAINT_CONSIDERATE,
  UPDATE_COMPLAINT_FINALIZE,
  DELETE_TRAFFIC_COMPLAINT,
  SET_PRIORITY_TO_TRAFFIC_COMPLAINT
} from "@core/store/action-constants";
import { pagination } from "@core/helpers/pagination";
import { datepicker } from "@core/helpers/datepicker";
import { ComplaintsService } from "@core/services/admin/complaints/ComplaintsService";
import {
  SET_COMPLAINTS,
  UPDATE_EXCLUDED_FILTERS,
  UPDATE_FILTERS,
  UPDATE_SORTINGS
} from "@core/store/mutation-constants";
import { Datepicker } from "@core/store/types/common";
import { complaintsModal } from "@core/store/modules/admin/complaints/modules/complaintsModal";
import { stateUpdater } from "@core/store/modules/common/stateUpdater";
import complaintsFieldsList from "./datasets/complaintsFieldsList.json";
import { complaintsChatModal } from "@core/store/modules/admin/complaints/modules/complaintsChatModal";
import { ManagersFilters } from "@core/store/modules/common/lists/managersFilters";

class Complaints implements Module<ComplaintsState, RootState> {
  public namespaced: boolean;
  public state: ComplaintsState;
  public modules: ModuleTree<ComplaintsState>;
  
  public mutations: MutationTree<ComplaintsState> = {
    [SET_COMPLAINTS] (state, payload) {
      state.data = { ...state.data, ...payload };
    },

    [UPDATE_FILTERS] (state, payload) {
      state.filters = { ...state.filters, ...payload };
    },

    [UPDATE_EXCLUDED_FILTERS] (state, payload) {
      state.excludedFilters = { ...state.excludedFilters, ...payload };
    },

    [UPDATE_SORTINGS] (state, { sort, order }) {
      state.sort = sort;
      state.order = order.toUpperCase();
    },

    ...stateUpdater.mutations
  }

  public actions: ActionTree<ComplaintsState, RootState> = {
    async [GET_COMPLAINTS] ({ state, commit }) {
      const { limit, offset } = pagination();
      const { datepicker, ...filters } = state.filters;
      const { dateStart, dateEnd } = datepicker as unknown as Datepicker;
      const { data: { trafficComplaint } } = await ComplaintsService.getTrafficComplaint(
        limit,
        offset,
        {
          ...filters,
          dateStart,
          dateEnd
        },
        state.excludedFilters,
        state.sort,
        state.order
      );

      commit(SET_COMPLAINTS, trafficComplaint);
      return trafficComplaint;
    },

    async [UPDATE_COMPLAINT_CONSIDERATE] ({ commit }, complaintId) {
      const items = await ComplaintsService.toConsiderateTrafficComplaint(complaintId);

      commit("LOCAL_UPDATE", { items });
    },

    async [UPDATE_COMPLAINT_FINALIZE] ({ commit }, { complaintId, ...input }) {
      const items = await ComplaintsService.resolveTrafficComplaint(complaintId, input);

      commit("LOCAL_UPDATE", { items });
    },

    async [CLOSE_TRAFFIC_COMPLAINT] ({ commit, dispatch }, { complaintId, status, comment }) {
      if (status === "TRAFFIC" || status === "NON_RELEVANT") {
        dispatch(UPDATE_COMPLAINT_FINALIZE, { complaintId, status, executorComment: comment });
      } else {
        const items = await ComplaintsService.closeTrafficComplaint(complaintId, comment);
        commit("LOCAL_UPDATE", { items });
      }
    },

    async [DELETE_TRAFFIC_COMPLAINT] ({ commit }, complaintId) {
      const items = await ComplaintsService.deleteTrafficComplaint(complaintId);

      commit("LOCAL_DELETE", { items });
    },

    async [SET_PRIORITY_TO_TRAFFIC_COMPLAINT] ({ commit }, params) {
      const items = await ComplaintsService.setPriorityToTrafficComplaint(params);

      commit("LOCAL_UPDATE", { items });
    },

    [UPDATE_FILTERS] ({ commit }, payload) {
      commit(UPDATE_FILTERS, payload);
    },

    [UPDATE_EXCLUDED_FILTERS] ({ commit }, payload) {
      commit(UPDATE_EXCLUDED_FILTERS, payload);
    },

    [UPDATE_SORTINGS] ({ commit }, payload) {
      commit(UPDATE_SORTINGS, payload);
    },

    [SET_EMPTY] ({ commit }, target) {
      commit(SET_EMPTY, target);
    }
  }
    
  private readonly initState: () => ComplaintsState;
  
  constructor (modules: ModuleTree<ComplaintsState>) {
    this.namespaced = true;
      
    this.initState = (): ComplaintsState => {
      return {
        data: null,
        filters: {
          datepicker: datepicker()
        },
        excludedFilters: {
          excludedAdvertiser: null
        },
        selectedFields: complaintsFieldsList,
        sort: null,
        order: null
      };
    };

    this.mutations[SET_EMPTY] = (state, target?: keyof ComplaintsState) => {
      if (target) {
        state[target] = this.initState()[target] as never;
      } else {
        Object.assign(state, this.initState());
      }
    };

    this.state = this.initState();
    this.modules = modules;
      
    return this;
  }
}

export const complaints = new Complaints({
  complaintsModal,
  managersFilters: new ManagersFilters(),
  complaintsChatModal
});