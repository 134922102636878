import { DetailingService } from "@core/services/admin/statistic/detailing/DetailingService";
import {
  GET_DETAIL,
  REMOVE_USER_CONTEXT_STORAGE,
  SET_EMPTY,
  SYNC_STATISTIC_FILTERS,
  UPDATE_SELECTED_LEADS
} from "@core/store/action-constants";
import { detailingRequestModal } from "@core/store/modules/admin/statistic/modules/detailing/modal/detailingRequestModal";
import { detailingStatusesList } from "@core/store/modules/admin/statistic/modules/detailing/lists/detailingStatusesList";
import { leadStatusesList } from "@core/store/modules/admin/statistic/modules/detailing/lists/LeadStatusesList";
import { detailingFieldsList } from "@core/store/modules/admin/statistic/modules/detailing/datasets/detailingFieldsList";
import { detailingGamblingFieldsList } from "@core/store/modules/admin/statistic/modules/detailing/datasets/detailingGamblingFieldsList";
import {
  SET_DETAIL,
  UPDATE_DETAILING_FILTERS,
  UPDATE_FILTERS,
  UPDATE_DETAILING_FIELDS,
  UPDATE_SORTINGS
} from "@core/store/mutation-constants";
import { RootState } from "@core/store/root-state";
import { detailingCopyLeadsModal } from "@core/store/modules/admin/statistic/modules/detailing/modal/detailingCopyLeadsModal";
import { DetailingListState, PartialDetailingState } from "@core/store/types/admin/statistic/detailing/detailingState";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { webmastersFilters } from "@core/store/modules/admin/statistic/modules/common/webmastersFilters";
import { externalWebmasterFilters } from "@core/store/modules/admin/statistic/modules/common/externalWebmasterFilters";
import { offersFilters } from "@core/store/modules/admin/statistic/modules/common/offersFilters";
import { filterFilters, prepareBaseFilters, prepareUserFilters } from "@core/store/modules/common/helpers/prepareFilters";
import { withdrawTypesList } from "@core/store/modules/admin/lists/withdrawTypesList";
import { flowsFilters } from "@core/store/modules/common/statistic/common/flowsFilters";
import { detailingPostbacksResending } from "@core/store/modules/common/statistic/detailing/detailingPostbacksResending";
import { pagination } from "@core/helpers/pagination";
import { datepicker } from "@core/helpers/datepicker";
import _isEmpty from "lodash/isEmpty";
import { VerticalsEnum } from "@core/store/types/admin/common/enums/VerticalsEnum";
import {
  detailingExtendHoldTimeModal
} from "@core/store/modules/admin/statistic/modules/detailing/modal/detailingExtendHoldTimeModal";
import {
  detailingCanceledLeadModal
} from "@core/store/modules/admin/statistic/modules/detailing/modal/detailingCanceledLeadModal";

const initialState = (): PartialDetailingState => {
  return {
    detail: null,
    filters: {
      datepicker: datepicker()
    },
    sort: null,
    order: null,
    selectedLeads: [],
    fields: []
  };
};

const state: () => PartialDetailingState = initialState;

const getters: GetterTree<PartialDetailingState, RootState> = {
  selectedFields: (state, getters, rootState) =>
    // TODO: Переписать этот прикол. Как только перепишем на новые таблицы.
    rootState.verticals.currentVertical === VerticalsEnum.GAMBLING ? detailingGamblingFieldsList() : detailingFieldsList()
};

const mutations: MutationTree<PartialDetailingState> = {
  [SET_EMPTY] (state, target?: keyof PartialDetailingState) {
    if (target) {
      state[target] = initialState()[target];
    } else {
      Object.assign(state, initialState());
    }
  },
  
  [SET_DETAIL] (state, items: DetailingListState["detail"]) {
    state.detail = items;
  },
  
  [UPDATE_DETAILING_FILTERS] (state, filters: DetailingListState["filters"]) {
    state.filters = { ...state.filters, ...filters };
  },

  [UPDATE_SELECTED_LEADS] (state, leads) {
    state.selectedLeads = leads;
  },
  
  [UPDATE_DETAILING_FIELDS] (state, fields) {
    state.fields = fields;
  },

  [UPDATE_SORTINGS] (state, { sort, order }) {
    state.sort = sort;
    state.order = order.toUpperCase();
  }
};

const actions: ActionTree<PartialDetailingState, RootState> = {
  async [GET_DETAIL] ({ state, commit, rootState: { verticals: { currentVertical: vertical } } }) {
    const { limit, offset } = pagination(100);
    
    const { datepicker: { dateStart, dateEnd }, phone, ...filters } = state.filters;
    const [withdrawType, subGoalType ] = filters.withdrawType?.split("-") || [null, null];

    const { leads } = await DetailingService.getDetail(
      limit,
      offset,
      {
        phone: phone?.length > 2 ? phone : null,
        vertical,
        ...filters,
        dateEnd,
        dateStart,
        withdrawType,
        subGoalType
      },
      state.fields,
      state.sort as string,
      state.order as string
    );
    commit(SET_DETAIL, leads);
  },
  
  [UPDATE_DETAILING_FILTERS] ({ commit }, filter) {
    commit(UPDATE_DETAILING_FILTERS, filter);
  },
  
  async [UPDATE_DETAILING_FIELDS] ({ commit, dispatch }, fields) {
    commit(UPDATE_DETAILING_FIELDS, fields);

    if (_isEmpty(fields)) {
      await dispatch(`userContextStorage/${ REMOVE_USER_CONTEXT_STORAGE }`, "table", { root: true });
    }
  },

  [UPDATE_SELECTED_LEADS] ({ commit, state }, { isChecked, dataItem }) {
    let filteredLeads = state.selectedLeads?.length ? [ ...state.selectedLeads ] : [];
    
    if (dataItem) {
      // Toggle one
      if (isChecked) {
        filteredLeads.push(dataItem);
      } else {
        filteredLeads = filteredLeads.filter(({ id }) => id !== dataItem.id);
      }
    } else {
      // Toggle all
      if (isChecked) {
        filteredLeads.push(...state.detail?.items || []);
      } else {
        const itemsIds = (state.detail?.items || []).map(({ id }) => id);
        filteredLeads = filteredLeads.filter(({ id }) => !itemsIds.includes(id));
      }
    }
    
    commit(UPDATE_SELECTED_LEADS, filteredLeads);
  },
  
  [SYNC_STATISTIC_FILTERS] ({ dispatch }, filters) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { currency, ...baseFilters } = prepareBaseFilters(filters);
    const userFilters = prepareUserFilters(filters, baseFilters);
    
    dispatch(`offersFilters/${ UPDATE_FILTERS }`, baseFilters);
    dispatch(`webmastersFilters/${ UPDATE_FILTERS }`, filterFilters(userFilters, ["webmasterId"]));
    dispatch(`externalWebmastersList/${ UPDATE_FILTERS }`, { dateStart: baseFilters.dateStart, dateEnd: baseFilters.dateEnd });
    dispatch(`flowsFilters/${ UPDATE_FILTERS }`, baseFilters);
    // dispatch(`externalWebmastersList/${ UPDATE_FILTERS }`, filterFilters(userFilters, ["externalWebmasterId"]));
  },
  
  [SET_EMPTY] ({ commit }, target?: string) {
    commit(SET_EMPTY, target);
    commit(`offersFilters/${ SET_EMPTY }`);
    commit(`webmastersFilters/${ SET_EMPTY }`);
    commit(`flowsFilters/${ SET_EMPTY }`);
    commit(`externalWebmastersList/${ SET_EMPTY }`);
    commit("withdrawTypesList/SET_EMPTY");
    commit("leadStatusesList/SET_EMPTY");
    commit("detailingStatusesList/SET_EMPTY");
    commit("detailingRequestModal/SET_EMPTY");
  }
};

export const detailing: Module<PartialDetailingState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    offersFilters,
    webmastersFilters,
    flowsFilters,
    externalWebmastersList: externalWebmasterFilters,
    leadStatusesList,
    withdrawTypesList,
    detailingStatusesList,
    detailingRequestModal,
    detailingPostbacksResending,
    detailingCopyLeadsModal,
    detailingCanceledLeadModal,
    detailingExtendHoldTimeModal
  }
};
