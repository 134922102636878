import { RootState } from "@core/store/root-state";
import {
  FilterInput,
  IdentifiableItem,
  FiltersData,
  FiltersState,
  FiltersResponseData
} from "@core/store/types/common/statistic/generic/statisticFilters";
import { ActionTree, Module, MutationTree } from "vuex";
import { UPDATE_FILTER_DATA, UPDATE_FILTERS, UPDATE_PAGINATION, UPDATE_SORTINGS } from "@core/store/mutation-constants";
import { GET_FILTER_DATA, GET_FILTER_INITIAL_DATA, SET_EMPTY } from "@core/store/action-constants";
import { updateList } from "@core/helpers/updateList";

export abstract class Filters<ItemType extends IdentifiableItem, FiltersType> implements Module<FiltersState<ItemType, FiltersType>, RootState> {
  private readonly initialState: () => FiltersState<ItemType, FiltersType>;
  
  public namespaced = true;
  
  public state: FiltersState<ItemType, FiltersType>;
  
  public mutations: MutationTree<FiltersState<ItemType, FiltersType>> = {
    [UPDATE_FILTER_DATA]: updateList,
  
    [UPDATE_PAGINATION] (state, payload = {}) {
      const { pagination, selectAll } = payload;
      if (selectAll) {
        state.pagination.page = 1;
        state.pagination.perPage = state.data?.count ?? 25;
      } else if (pagination) {
        state.pagination = pagination;
      } else if (state.data?.items && state.data.items.length >= state.pagination.perPage) {
        state.pagination.page = Math.ceil(state.data.items.length / state.pagination.perPage) + 1;
      }
    },
    
    [UPDATE_FILTERS] (state, filters) {
      if (filters?.label !== state.label) {
        state.pagination.page = 1;
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { label, ...fields } = filters;
  
      state.filters = { ...state.filters, ...fields };
      state.label = filters?.label;
    },

    [UPDATE_SORTINGS] (state, { sort, order }): void {
      state.sort = sort;
      state.order = order.toUpperCase();
    },
  
    [SET_EMPTY]: state => Object.assign(state, this.initialState())
  };
  
  public actions: ActionTree<FiltersState<ItemType, FiltersType>, RootState> = {
    [UPDATE_PAGINATION] ({ commit }, payload) {
      commit(UPDATE_PAGINATION, payload);
    },
    
    [UPDATE_FILTERS] ({ commit }, filters) {
      commit(UPDATE_FILTERS, filters);
    },

    [UPDATE_SORTINGS] ({ commit }, payload) {
      commit(UPDATE_SORTINGS, payload);
    }
  };
  
  abstract getData (offset: number, limit: number, filters?: FilterInput, initialFilters?: FilterInput, sort: string, order: "DESC" | "ASC"): Promise<FiltersResponseData<ItemType>>;
  
  abstract getInitialFilters (payload: any): FilterInput | undefined;
  
  prepareInitialData (initialData?: FiltersData<ItemType>): any {
    const length = initialData?.items?.length;
    if (length) {
      if (length === 1){
        return initialData?.items?.[0];
      }
      return initialData?.items?.map(item => item.id);
    }
    return null;
  }
  
  constructor () {
    this.initialState = () => ({
      data: null,
      pagination: {
        page: 1,
        perPage: 25
      },
      filters: {},
      sort: null,
      order: null,
      label: null
    });
    
    this.state = this.initialState();
    
    this.actions[GET_FILTER_DATA] = async ({ state, commit }) => {
      const { pagination: { page, perPage }, filters, sort, order } = state;
      const offset = (page - 1) * perPage;
      
      const { data } = await this.getData(offset, perPage, filters, {}, sort, order);
      commit(UPDATE_FILTER_DATA, data);
    };
    
    this.actions[GET_FILTER_INITIAL_DATA] = async ({ state, commit }, payload) => {
      const { pagination: { perPage }, filters, sort, order } = state;
      const initialFilters = this.getInitialFilters(payload);
      
      const { data, initialData } = await this.getData(0, perPage, filters, initialFilters, sort, order);
      
      if (data?.items && initialData?.items) {
        data.items.push(...initialData.items);
      }
      
      commit(UPDATE_FILTER_DATA, data);
      
      return this.prepareInitialData(initialData);
    };
  }
}
