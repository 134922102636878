<template>
    <div
        v-if="isActive"
        class="captcha">
        <VueHcaptcha
            ref="hcaptcha"
            size="invisible"
            :sitekey="sitekey"
            @verify="$emit('onVerify', $event)"
            @expired="onCaptchaExpired">
        </VueHcaptcha>
    </div>
</template>

<script>
  import VueHcaptcha from "@hcaptcha/vue-hcaptcha";

  export default {
    name: "Captcha",
    components: {
      VueHcaptcha
    },
    computed: {
      sitekey () {
        return process.env.VUE_APP_HCAPTCHA_SITEKEY;
      },

      isActive () {
        return process.env.VUE_APP_CAPTCHA_PROVIDER === "hcaptcha";
      }
    },
    methods: {
      // TODO: добавить метод получения токена капчи и валидацию
      onCaptchaExpired () {
        this.$refs.hcaptcha.reset();
      }
    }
  };

</script>

<style scoped lang="scss">
  .captcha {
    width: 156px;
    margin: auto;
  }

</style>