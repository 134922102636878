import {
  RemoveWebmasterParametersPayload,
  PartialOfferWebmastersState,
  ResponseOfferWebmaster,
  RequestIndividualRate,
  CreateIndividualRate,
  IndividualRate,
  OfferWebmaster
} from "@core/store/types/admin/offer/OfferWebmasters";
import { OfferEditWebmastersServices } from "@core/services/admin/offer/edit/OfferEditWebmastersServices";
import { PaginationOutput } from "@core/store/types/PaginationOutput";
import { useOfferEdit } from "@/stores/admin/offer/offerEditStore";
import { useStateUpdater } from "@/helpers/useStateUpdater";
import { hasPermissions } from "@core/mixins/permissions";
import { capitalizeFirstLetter } from "@core/filters";
import { defineStore, storeToRefs } from "pinia";
import store from "@/store";
import Vue from "vue";
import { ToastProgrammatic } from "buefy";
import i18n from "@core/plugins/i18n";
import { BNoticeConfig } from "buefy/types/components";

export function useOfferWebmastersRates (param = "external") {
  return defineStore(`admin/offer/edit/webmastersRates/${ param }`, {
    state: (): PartialOfferWebmastersState => ({
      webmasters: {},
      filters: {
        loginOrIntId: null,
        webmasterId: null,
        manager: null
      },
      pagination: {
        page: 1,
        perPage: 25
      }
    }),

    getters: {
      getSelectedWebmaster: ({ webmasters }) => function (ids: string[]): OfferWebmaster[] | undefined {
        if (Array.isArray(webmasters?.items)) {
          // @ts-ignore
          return ids?.map(id => webmasters.items?.find((item: OfferWebmaster) => item.webmaster.id === id));
        }
      },
      userPermissions: (): boolean => store.state.auth.userPermissions,
      hasVisibleExcludeWebmastersOwn (): boolean { return hasPermissions(["OFFERS.EXCLUDE_WEBMASTERS.OWN"], this.userPermissions); },
      hasVisibleExcludeWebmastersAll (): boolean { return hasPermissions(["OFFERS.EXCLUDE_WEBMASTERS.ALL"], this.userPermissions); }
    },

    actions: {
      UPDATE_OFFER_WEBMASTERS (webmasters: PaginationOutput<OfferWebmaster[]>): void {
        if (this.webmasters) {
          this.webmasters = {
            count: webmasters.count,
            items: this.webmasters?.items?.concat(webmasters.items as OfferWebmaster[])
          };
        }
      },

      UPDATE_OFFER_WEBMASTER_RATE ({ newRate, index }: { newRate: IndividualRate; index: number }): void {
        if (this.webmasters?.items) {
          if (this.webmasters.items[index]?.individualRate) {
            Vue.set(this.webmasters.items[index], "individualRate", newRate);
          }
          if (newRate?.agentCommission) {
            Vue.set(this.webmasters.items[index], "agentCommission", newRate.agentCommission);
          }
        }
      },

      UPDATE_LOADING ({ index, params, value }: any): void {
        if (this.webmasters?.items) {
          for (const key in params) {
            if (params.hasOwnProperty(key)) {
              const prop = `is${ capitalizeFirstLetter(key) }Loading`;
              Vue.set(this.webmasters.items[index], prop, value);
            }
          }
        }
      },

      _UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD (payload: any, individualRate: any, isLowerMinimalCommission: any): void {
        if (this.webmasters && this.webmasters.items) {
          const webmastersIds = this.webmasters.items.map(item => item.webmaster.id);

          this.webmasters.items = this.webmasters.items.map((item, index) => {
            const newReward = payload[webmastersIds[index]];
            const newIndividualRate = individualRate[webmastersIds[index]];
            const _isLowerMinimalCommission = isLowerMinimalCommission[webmastersIds[index]];
            item.maximalAllowedRate = newReward ? newReward : item.maximalAllowedRate;
            item.individualRate = { ...item.individualRate, ...newIndividualRate };
            item.isLowerMinimalCommission = _isLowerMinimalCommission;
            return item;
          });
        }
      },

      async GET_OFFER_EXTERNAL_WEBMASTERS () {
        const store = useOfferEdit();

        try {
          const { pagination, filters } = this;

          if (store.offerId) {
            const limit = pagination.perPage;
            const offset = (pagination.page - 1) * limit;
            const data =
              // @ts-ignore
              await OfferEditWebmastersServices.getOfferExternalWebmasters({ ...filters, hasExternalWebmaster: true }, store.offerId, limit, offset);

            if (offset === 0) {
              this.webmasters = data;
            } else {
              this.UPDATE_OFFER_WEBMASTERS(data);
            }
          }
        } catch (e) {
          throw e;
        }
      },

      async ADD_OFFER_WEBMASTER_INDIVIDUAL_RATE ({ index, params }: { index: number; params: any; }): Promise<void> {
        const store = useOfferEdit();

        this.UPDATE_LOADING({ index, params, value: true });

        try {
          if (this.webmasters?.items && store.offerId) {
            const { webmaster } = this.webmasters.items[index];
            const prop = Object.keys(params)[0];
            const newRate: CreateIndividualRate =
                Object.assign(
                  { amount: null, holdTime: null, withdrawType: null, webmasterId: webmaster.id },
                  { [prop === "rate" ? "amount" : prop]: isNaN(params[prop]) ? params[prop] : +params[prop] }
                );

            const data = await OfferEditWebmastersServices.addIndividualRates(store.offerId, [newRate]);
            const individualRate = data[0];

            if (individualRate.isLowerMinimalCommission) {
              setTimeout(() => {
                ToastProgrammatic.open({
                  message: i18n.t("admin.offer.form.webmasters.tooltip.lowerMinimalCommission"),
                  type: "is-warning",
                  duration: 4000
                } as BNoticeConfig);
              }, 3000);
            }

            store.UPDATE_MINIMAL_ALLOWED_REWARD();
            this.UPDATE_OFFER_WEBMASTER_RATE({ newRate: individualRate as unknown as IndividualRate, index });
            this.UPDATE_LOADING({ index, params, value: false });
          }
        } catch (e) {
          this.UPDATE_LOADING({ index, params, value: false });
          throw e;
        }
      },

      async UPDATE_OFFER_WEBMASTER_INDIVIDUAL_RATE ({ index, params }: { index: number; params: any; }): Promise<void> {
        const store = useOfferEdit();
        const { offerId } = storeToRefs(store);

        this.UPDATE_LOADING({ index, params, value: true });

        try {
          if (this.webmasters?.items) {
            const { webmaster: _webmaster, individualRate, externalWebmasterId, agentCommission } = this.webmasters.items[index];
            const { rate, hold } = individualRate || {};
            const prop = Object.keys(params)[0];
            const propValue = !params[prop] || isNaN(params[prop]) ? params[prop] : +params[prop];

            const newRate: RequestIndividualRate =
                Object.assign(
                  // @ts-ignore
                  {
                    holdTime: hold,
                    amount: rate?.value,
                    externalWebmasterId,
                    agentCommission: agentCommission?.value
                  },
                  { [prop === "rate" ? "amount" : prop]:
                      !["rate", "agentCommission"].includes(prop) && propValue === 0 ? null : propValue }
                );

            const data = await OfferEditWebmastersServices.makeIndividualRate(offerId.value, _webmaster.id, newRate);

            if (data?.isLowerMinimalCommission) {
              setTimeout(() => {
                ToastProgrammatic.open({
                  message: i18n.t("admin.offer.form.webmasters.tooltip.lowerMinimalCommission"),
                  type: "is-warning",
                  duration: 4000
                } as BNoticeConfig);
              }, 3000);
            }

            store.UPDATE_MINIMAL_ALLOWED_REWARD();
            this.UPDATE_OFFER_WEBMASTER_RATE({ newRate: data as unknown as IndividualRate ?? newRate, index });
            this.UPDATE_LOADING({ index, params, value: false });
          }
        } catch (e) {
          this.UPDATE_LOADING({ index, params, value: false });
          throw e;
        }
      },

      async PROXY_OFFER_WEBMASTER_INDIVIDUAL_RATE ({ index, params }: { index: number; params: any; }): Promise<void> {
        const prop = Object.keys(params)[0];
        params[prop] = params[prop] === "" ? 0 : params[prop];

        if (this.webmasters?.items) {
          // const { individualRate, agentCommission } = this.webmasters.items[index];

          // if (!individualRate) {
          //   await this.ADD_OFFER_WEBMASTER_INDIVIDUAL_RATE({ index, params });
          // } else {
          //   const { id, hold, ...values } = individualRate || {};
          //   let isEmptyRate = true;
          //
          //   for (const key in values as IndividualRate) {
          //     if (values.hasOwnProperty(key)) {
          //       if (values[key] && values[key] !== "") {
          //         isEmptyRate = false;
          //       }
          //     }
          //   }
          //   if (isEmptyRate && !params[prop]) {
          //     // await this.DELETE_OFFER_WEBMASTER_INDIVIDUAL_RATE({ index, params });
          //   } else {
          await this.UPDATE_OFFER_WEBMASTER_INDIVIDUAL_RATE({ index, params });
          // }
          // }
        }
      },

      async GET_OFFER_WEBMASTERS (): Promise<void> {
        try {
          const store = useOfferEdit();
          const { pagination, filters } = this;

          if (store.offerId) {
            const limit = pagination.perPage;
            const offset = (pagination.page - 1) * limit;

            const item = await OfferEditWebmastersServices.getOfferWebmasters(filters, store.offerId, limit, offset);

            if (offset === 0) {
              this.webmasters = item;
            } else {
              this.UPDATE_OFFER_WEBMASTERS(item);
            }
          }
        } catch (e) {
          throw e;
        }
      },

      async UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD (): Promise<void> {
        const store = useOfferEdit();
        const { offerId } = storeToRefs(store);

        try {
          if (this.webmasters?.items?.length && offerId.value) {
            const limit = this.pagination.perPage * this.pagination.page;
            const webmastersIds = this.webmasters.items.map(user => user.webmaster.id);

            let items = [] as any;

            if (param === "external") {
              // @ts-ignore
              const { items: _items } = await OfferEditWebmastersServices.getOfferExternalWebmasters({ ...this.filters, hasExternalWebmaster: true }, offerId.value, limit, 0);
              items = _items;
            } else {
              const { data: { offer: { webmasters: { items: _items } } } } =
                  await OfferEditWebmastersServices.getMaximalAllowedInternalWebmastersReward(offerId.value, webmastersIds, limit);
              items = _items;
            }

            const webmastersReward: { [key: string]: unknown } = {};
            const individualRate: { [key: string]: unknown } = {};
            const isLowerMinimalCommission: { [key: string]: unknown } = {};

            items.forEach((item: ResponseOfferWebmaster) => {
              webmastersReward[item.webmaster.id] = item.maximalAllowedRate;
              individualRate[item.webmaster.id] = item.individualRate;
              isLowerMinimalCommission[item.webmaster.id] = item.isLowerMinimalCommission;
            });

            this._UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD(webmastersReward, individualRate, isLowerMinimalCommission);
          }
        } catch (e: any) {
          throw new Error(e);
        }
      },

      async DELETE_OFFER_WEBMASTERS (payload: { id: string; webmasters: string[] | null }): Promise<void> {
        const store = useOfferEdit();

        if (store.offerId) {
          const { id, webmasters } = payload;
          await OfferEditWebmastersServices.excludeWebmastersFromOffer(store.offerId, { webmasters });
          // @ts-ignore
          useStateUpdater(useOfferWebmastersRates("internal")).LOCAL_DELETE({ items: [{ id }], target: "webmasters" });
          store.UPDATE_MINIMAL_ALLOWED_REWARD();
        }
      },

      async DELETE_OFFER_EXTERNAL_WEBMASTERS (payload: RemoveWebmasterParametersPayload): Promise<void> {
        const store = useOfferEdit();

        if (store.offerId) {
          const { webmasterId, id, ...otherFields } = payload;

          await OfferEditWebmastersServices.makeIndividualRate(store.offerId, webmasterId, otherFields);
          // @ts-ignore
          useStateUpdater(useOfferWebmastersRates("external")).LOCAL_DELETE({ items: [{ id }], target: "webmasters" });

          const offerStore = useOfferEdit();
          offerStore.UPDATE_MINIMAL_ALLOWED_REWARD();

          useOfferWebmastersRates("internal").UPDATE_MAXIMAL_ALLOWED_WEBMASTER_REWARD();
        }
      }
    }
  })();
}
