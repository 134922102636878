<template>
    <section>
        <b-sidebar
            open
            fullheight
            :reduce="isReduce"
            :can-cancel="false"
            :class="{ 'active-sidebar-mobile': isActiveAsideMenuMobile_ }"
            type="is-white">
            <div class="is-flex">
                <slot
                    name="burger"
                    :isActive="isReduce"
                    :actionReduce="actionReduce">
                </slot>
                <Logo></Logo>
            </div>
            <Role>
                <template #webmaster>
                    <AsideMenuWebmaster
                        ref="menuWebmaster"
                        :is-reduce="isReduce"
                        @resetReduce="resetReduce"
                        @closeAsideMenu="closeAsideMenu">
                    </AsideMenuWebmaster>
                </template>
                <template #advertiser>
                    <AsideMenuAdvertiser
                        ref="menuAdvertiser"
                        :is-reduce="isReduce"
                        @resetReduce="resetReduce"
                        @closeAsideMenu="closeAsideMenu">
                    </AsideMenuAdvertiser>
                </template>
                <template #admin>
                    <AsideMenuAdmin
                        ref="menuAdmin"
                        :is-reduce="isReduce"
                        @resetReduce="resetReduce"
                        @closeAsideMenu="closeAsideMenu">
                    </AsideMenuAdmin>
                </template>
            </Role>
            <footer :class="{'is-mini': isReduce }">
                <div
                    v-if="$saas.main"
                    class="column has-text-centered copy">
                    <div style="font-size: 11px">
                        <span class="copyright">©</span> LemonAD 2017 &mdash; 2024
                        <div class="copyright">
                            {{ $t("common.aside.copyright") }}
                        </div>
                    </div>
                </div>
            </footer>
        </b-sidebar>

        <div
            v-if="isActiveAsideMenuMobile"
            class="sidebar-background"
            @click="closeAsideMenu">
        </div>
    </section>
</template>

<script>
  import AsideMenuAdmin from "@/components/Admin/Menu/AsideMenu";
  import AsideMenuAdvertiser from "@/components/Advertiser/Menu/AsideMenu";
  import Logo from "@/components/Common/Logo";
  import Role from "@/components/Common/Role";
  import AsideMenuWebmaster from "@/components/Webmaster/Menu/AsideMenu";
  import arrowCollapseLeft from "@/assets/AsideMenu/arrow-collapse-left.svg";
  import arrowCollapseRight from "@/assets/AsideMenu/arrow-collapse-right.svg";
  import { mapState } from "vuex";
  import { useBadges } from "@/stores/common/badgesStore";
  import { useVerticals } from "@/stores/common/auth/verticalsStore";

  export default {
    name: "AsideMenu",
    components: {
      Logo,
      Role,
      AsideMenuWebmaster,
      AsideMenuAdvertiser,
      AsideMenuAdmin
    },

    props: {
      isActiveAsideMenuMobile: {
        type: Boolean,
        default: false
      }
    },

    setup () {
      const verticalsStore = useVerticals();

      return {
        badgesStore: useBadges(),
        isVisibleByAllVerticals: verticalsStore.isVisibleByAllVerticals
      };
    },

    created () {
      this.updateBadges();
      window.addEventListener("resize", this.controlResizeMenu);
      this.controlResizeMenu();
    },

    data () {
      return {
        isReduce: false,
        badgesInstance: null
      };
    },

    computed: {
      ...mapState({
        role: state => state.auth.role
      }),

      icons () {
        return {
          left: arrowCollapseLeft,
          right: arrowCollapseRight
        };
      },

      isActiveAsideMenuMobile_: {
        get () {
          return this.isActiveAsideMenuMobile;
        },
        set (value) {
          this.$emit("update:isActiveAsideMenuMobile", value);
        }
      }
    },

    methods: {
      actionReduce () {
        this.isReduce = !this.isReduce;

        this.resetActiveCollapseByUser();
      },

      resetActiveCollapse (value) {
        this.$refs[value]?.$refs?.menuList?.resetActiveCollapse();
      },

      resetActiveCollapseByUser () {
        if (this.isReduce) {
          if (this.role === "admin") {
            this.resetActiveCollapse("menuAdmin");
          } else if (this.role === "webmaster") {
            this.resetActiveCollapse("menuWebmaster");
          } else if (this.role === "advertiser") {
            this.resetActiveCollapse("menuAdvertiser");
          }
        }
      },

      closeAsideMenu () {
        this.isActiveAsideMenuMobile_ = !this.isActiveAsideMenuMobile;
      },

      resetReduce () {
        this.isReduce = false;
      },

      controlResizeMenu () {
        const WIDTH = 1215;
        this.isReduce = window.innerWidth < WIDTH;
        if (window.innerWidth < WIDTH) {
          this.resetActiveCollapseByUser();
        }
      },

      updateBadges () {
        if (this.role === "admin" && this.isVisibleByAllVerticals) {
          this.badgesStore.GET_BADGES();
          this.badgesInstance = setInterval(this.badgesStore.GET_BADGES, 15000);
        }
      }
    },

    watch: {
      isReduce (value) {
        if (value) {
          document.body.classList.add("is-mini");
        } else {
          document.body.classList.remove("is-mini");
        }
        this.$emit("update:isReduce", value);
      },

      isActiveAsideMenuMobile_ (value) {
        this.isReduce = !value;
      }
    },

    beforeDestroy () {
      clearInterval(this.badgesInstance);
      this.badgesStore.$reset();
    },

    destroyed () {
      window.removeEventListener("resize", this.controlResizeMenu);
    }
  };
</script>

<style lang="scss" scoped>
  $primary-color: #FFBA40FF;
  $light-color: #B5B5B5;
  $transition-options: 250ms ease-in-out 50ms;

  ::v-deep {
    .button {
      border: none;
      &:hover, &:focus:hover {
        color: $primary-color;
      }

      &:focus {
        color: $light-color;
      }
    }

    .sidebar-content {
      transition: width $transition-options,
      left $transition-options;

      //noinspection SassScssUnresolvedMixin
      @include touch {
        //noinspection SassScssResolvedByNameOnly
        left: -($aside-width-widescreen + 1rem) !important;

        &.is-mini {
          //noinspection SassScssResolvedByNameOnly
          left: -($aside-width-until-widescreen + 1rem) !important;
        }
      }
    }
  }

  .sidebar-content {
    .logo {
      width: 100%;
    }

    .menu {
      width: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      position: relative;
      height: 100%;
      //noinspection SassScssResolvedByNameOnly
      max-height: calc(100vh - #{ $aside-width-until-widescreen } * 2);

      //noinspection CssInvalidPropertyValue
      overflow: -moz-scrollbars-none;
      &::-webkit-scrollbar { width: 0; }

      ::v-deep {
        .badges {
          height: 18px;
          min-width: 18px;
          padding: 0 .2rem;

          font-size: 12px;
          color: #fff !important;
          border-radius: 4px !important;

          animation: reduce 250ms ease-in-out;
          pointer-events: none;
        }

        .menu-list {
          //noinspection SassScssResolvedByNameOnly
          @include icon-with-update-mark($aside-icon-width);

          top: 0;
          left: 0;
          right: -100vw;
          bottom: 0;
          position: absolute;
          overflow-x: auto;
          overflow-y: scroll;

          //noinspection SassScssUnresolvedMixin
          @include until-widescreen {
            height: 100%;
            overflow-y: scroll;
          }
        }

        .menu-label {
          padding: 0 $default-padding * 0.5;

          &:first-of-type {
            margin-top: 0;
          }
        }
      }
    };

    .logo + div {
      &:nth-child(2) {
        height: 100%;
        overflow-y: auto;

        div {
          height: 100%;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  ::v-deep {
    .sidebar-content {
      .aside-menu-button {
        span {
          transition: all 250ms ease-in-out;
        }
      }

      &.is-mini {
        .aside-menu-button {
          span {
            height: 3px;
            width: 3px;
            border-radius: 6px;
            left: calc(50% - 3px);
          }
        }
      }
    }
  }

  .sidebar-content.is-mini {
    .logo {
      ::v-deep {
        .logo-image {
          opacity: 0;
          transition: opacity 250ms ease-in-out;
        }

        .logo-image-square {
          opacity: 1;
          transition: opacity 250ms ease-in-out 300ms;
        }
      }
    }

    .menu {
      ::v-deep {
        .badges {
          margin-right: 0 !important;
        }

        .menu-item {
          //noinspection SassScssResolvedByNameOnly
          width: $aside-width-until-widescreen;

          a {
            justify-content: start;

            .icon-wrapper {
              width: 100%;
              @include transition(width);
            }
          }

          &-icon {
            margin-right: 0;
          }

          &-text {
            @include transition(opacity);
            opacity: 0;
            position: absolute;
            left: 40px;
            pointer-events: none;
          }
        }

        @keyframes reduce {
          from {
            opacity: 0;
          }

          to {
            opacity: 1;
          }
        }

        .menu-label {
          padding: 0 $default-padding * 0.5;

          &:first-of-type {
            margin-top: 0;
          }
        }
      }
    }
  }

  .sidebar-content:not(.is-mini) {
    .logo {
      margin: auto;

      ::v-deep {
        .logo-image-square {
          position: absolute;
          opacity: 0;
        }
      }
    }

    .menu {
      ::v-deep {
        .menu-item {
          width: $aside-width-widescreen;

          &-icon {
            margin-right: 10px;
          }
        }
      }
    }
  }

  footer {
    height: 100%;
    display: flex;
    align-items: flex-end;

    .collapse-button {
      display: none;
      justify-content: flex-start;

      //noinspection SassScssUnresolvedMixin
      @include desktop {
        display: flex;
      }
    }

    .copy {
      div {
        max-height: 30px;
      }
    }
  }

  footer.is-mini {
    .collapse-button {
      .button {
        flex: 1;
        animation: button-content 250ms ease-in-out;
      }

      @keyframes button-content {
        from {
          flex: none;
        }

        to {
          flex: 1;
        }
      }
    }

    .copyright {
      display: none;
    }
  }

  .active-sidebar-mobile {
    ::v-deep {
      .sidebar-content {
        left: 0 !important;
      }
    }
  }

  .sidebar-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 28;

    width: 100%;
    height: 100%;
  }
</style>
