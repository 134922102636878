import Service from "@core/services/common/Service";
import {
  ComplaintsState,
  ComplaintsStateResponse,
  UpdateConsiderateComplaintReason,
  UpdateFinalizeComplaintReason,
  UpdateTrafficComplaintInputReason
} from "@core/store/types/admin/complaints";
import { AxiosPromise } from "axios";
import TrafficComplaintQuery from "./graphql/TrafficComplaint.graphql";
import TrafficComplaintCommentsQuery from "./graphql/TrafficComplaintComments.graphql";
import CreateTrafficComplaintQuery from "./graphql/CreateTrafficComplaint.graphql";
import ToConsiderateTrafficComplaintQuery from "./graphql/ToConsiderateTrafficComplaint.graphql";
import setPriorityToTrafficComplaint from "./graphql/setPriorityToTrafficComplaint.graphql";
import ResolveTrafficComplaintQuery from "./graphql/ResolveTrafficComplaint.graphql";
import AddTrafficComplaintCommentQuery from "./graphql/AddTrafficComplaintComment.graphql";
import CloseCallCenterTrafficComplaint from "./graphql/CloseCallCenterTrafficComplaint.graphql";
import deleteTrafficComplaintQuery from "./graphql/deleteTrafficComplaint.graphql";
import trafficComplaintContext from "./graphql/TrafficComplaintContext.graphql";
import { ComplaintsModalInputReason, ComplaintsModalReason } from "@core/store/types/admin/complaints/complaintsModal";
import {
  ComplaintsChatModalInput,
  ComplaintsChatModalResponse
} from "@core/store/types/admin/complaints/complaintsChatModal";
import { prepareExcludedFilters } from "@core/helpers/prepareExcludedFilters";

export class ComplaintsService extends Service {
  public static getTrafficComplaint (
    limit: number,
    offset: number,
    filters: ComplaintsState["filters"],
    excludedFilters: ComplaintsState["excludedFilters"],
    sort: ComplaintsState["sort"],
    order: ComplaintsState["order"]
  ): AxiosPromise<ComplaintsStateResponse> {
    try {
      return this.api.get("", {
        params: {
          query: TrafficComplaintQuery,
          variables: {
            limit,
            offset,
            filters,
            excludedFilters: prepareExcludedFilters(excludedFilters),
            sort,
            order
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static createTrafficComplaint (
    input: ComplaintsModalInputReason
  ): AxiosPromise<ComplaintsModalReason> {
    try {
      return this.api.post("", {
        query: CreateTrafficComplaintQuery,
        variables: { input }
      });
    } catch (e) {
      throw e;
    }
  }

  public static getRequiredApprove (
    negativeEndDate: Nullable<string | Date>,
    negativeStartDate: Nullable<string | Date>,
    offerId: Nullable<string>,
    positiveEndDate: Nullable<string | Date>,
    positiveStartDate: Nullable<string | Date>,
    webmasterId: Nullable<string>
  ) {
    try {
      return this.api.get("", {
        params: {
          query: trafficComplaintContext,
          variables: {
            negativeEndDate,
            negativeStartDate,
            offerId,
            positiveEndDate,
            positiveStartDate,
            webmasterId
          }
        }
      });
    } catch (e) {
      throw e;
    }
  }

  public static async toConsiderateTrafficComplaint (
    complaintId: string
  ): Promise<UpdateConsiderateComplaintReason> {
    try {
      const { data: { toConsiderateTrafficComplaint } } = await this.api.post("", {
        query: ToConsiderateTrafficComplaintQuery,
        variables: { complaintId }
      });

      return toConsiderateTrafficComplaint;
    } catch (e) {
      throw e;
    }
  }

  public static async resolveTrafficComplaint (
    complaintId: string,
    input: UpdateTrafficComplaintInputReason
  ): Promise<UpdateFinalizeComplaintReason> {
    try {
      const { data: { resolveTrafficComplaint } } = await this.api.post("", {
        query: ResolveTrafficComplaintQuery,
        variables: { complaintId, input }
      });

      return resolveTrafficComplaint;
    } catch (e) {
      throw e;
    }
  }

  public static async getComplaintComments (id: string): Promise<ComplaintsStateResponse> {
    try {
      const { data: { trafficComplaint: { items: [item] } } } = await this.api.get("", {
        params: {
          query: TrafficComplaintCommentsQuery,
          variables: { filters: { id } }
        }
      });

      return item;
    } catch (e) {
      throw e;
    }
  }

  public static async addTrafficComplaintComment (
    input: ComplaintsChatModalInput
  ): Promise<ComplaintsChatModalResponse> {
    try {
      const { data: { addTrafficComplaintComment } } = await this.api.post("", {
        query: AddTrafficComplaintCommentQuery,
        variables: { input }
      });

      return addTrafficComplaintComment;
    } catch (e) {
      throw e;
    }
  }

  public static async closeTrafficComplaint (
    complaintId: string,
    comment: string
  ): Promise<ComplaintsChatModalResponse> {
    try {
      const { data: { closeCallCenterTrafficComplaint } } = await this.api.post("", {
        query: CloseCallCenterTrafficComplaint,
        variables: {
          complaintId,
          comment
        }
      });

      return closeCallCenterTrafficComplaint;
    } catch (e) {
      throw e;
    }
  }

  public static async deleteTrafficComplaint (
    complaintId: string
  ): Promise<ComplaintsChatModalResponse> {
    try {
      const { data: { deleteTrafficComplaint } } = await this.api.post("", {
        query: deleteTrafficComplaintQuery,
        variables: {
          complaintId
        }
      });

      return deleteTrafficComplaint;
    } catch (e) {
      throw e;
    }
  }

  public static async setPriorityToTrafficComplaint ({ complaintId, priority }: { complaintId: string, priority: string }): Promise<ComplaintsChatModalResponse> {
    try {
      const { data: { setPriorityToTrafficComplaint: data } } = await this.api.post("", {
        query: setPriorityToTrafficComplaint,
        variables: {
          complaintId,
          input: { priority }
        }
      });

      return data;
    } catch (e) {
      throw e;
    }
  }
}