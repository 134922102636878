<template>
    <component
        :is="componentIs"
        :class="[{ 'has-icon': !!item.icon }, inner ? 'inner-item' : 'external-item']"
        :to="itemTo"
        v-bind="{ ...href }"
        :target="item.target"
        active-class="is-active"
        :event="isActive ? '' : undefined"
        @mousedown.native="cancelActiveRequests">
        <span
            v-if="inner"
            class="menu-item-dash">
        </span>
        <span
            v-if="!inner && item.hasBadges"
            class="menu-item-point">
        </span>
        <InlineSvg
            v-if="!inner"
            class="menu-item-icon"
            :src="item.icon"
            :alt="item.label"
            :aria-label="item.label">
        </InlineSvg>
        <div
            :class="[
                'flex', 'is-align-items-center',
                { 'is-flex-direction-row-reverse': !inner && item.hasBadges }
            ]">
            <span
                v-if="item.label"
                class="menu-item-text">
                {{ item.label }}
            </span>
            <b-tag
                v-if="inner && item.badgesCount"
                type="is-warning"
                rounded
                class="badges"
                :style="{ 'margin-right': `${ !inner && item.hasBadges ? '.47rem' : '' }` }">
                {{ badgesCount }}
            </b-tag>
            <b-tag
                v-if="item.isNew"
                type="is-success"
                rounded
                class="px-2 ml-2 tag-is-new">
                NEW
            </b-tag>
        </div>
    </component>
</template>

<script>
  import AsideMenuList from "@/components/Common/AsideMenuList";
  import InlineSvg from "vue-inline-svg";
  import { hasPermissions } from "@core/mixins/permissions";
  import { mapState } from "vuex";
  import { cancelSource } from "@core/services/common/cancelSource";
  import _intersection from "lodash/intersection";

  export default {
    name: "AsideMenuItem",
    components: {
      AsideMenuList,
      InlineSvg
    },

    props: {
      item: {
        type: Object,
        default: null
      },

      inner: {
        type: Boolean,
        default: false
      },

      labels: {
        type: Array,
        default: () => []
      }
    },

    computed: {
      ...mapState({
        filteredMenuChildren ({ auth }) {
          return this.item.children?.filter(path => hasPermissions(path.permissions, auth.userPermissions)) || [];
        }
      }),

      isActive () {
        return this.itemTo?.name === this.$route.name;
      },

      componentIs () {
        return this.item.to || this.filteredMenuChildren?.length && this.filteredMenuChildren[0]?.to ? "router-link" : "a";
      },

      itemTo () {
        const commonName = _intersection(this.filteredMenuChildren?.map(({ to }) => to?.name), [this.$route?.name])[0];
        const externalTo = commonName ? { name: commonName } : this.filteredMenuChildren[0]?.to;
        return this.item.to ?? externalTo ?? { name: "" };
      },

      href () {
        if (this.itemHref) {
          return {
            "href": this.itemHref
          };
        } return {};
      },

      itemHref () {
        return this.item.href ? this.item.href : undefined;
      },

      badgesCount () {
        const count = this.item.badgesCount;
        const maxCount = 99;

        if (count <= maxCount) {
          return this.item.badgesCount;
        } else if (count > maxCount) {
          return this.$t("common.badges.moreBadges");
        } else {
          return null;
        }
      }
    },
    methods: {
      cancelActiveRequests () {
        if (!this.isActive) {
          cancelSource.cancel("cancelled");
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  body:has(.is-mini) .tag-is-new {
    display: none;
  }

  a {
    $size-icon: 18px;
    $collapse-menu-color: #969696;

    padding: .7rem .75rem;
    //noinspection SassScssResolvedByNameOnly
    fill: $menu-item-color;
    width: 100%;
    display: flex;
    align-items: center;

    .menu-item {
      $size-point: 8px;
      position: relative;

      &-dash {
        flex: 0 0 6px;
        height: 6px;

        margin-right: 8px;
        border-radius: 50%;
        opacity: 0;
        //noinspection SassScssResolvedByNameOnly
        background-color: $collapse-menu-color !important;
      }

      &-point {
        position: absolute;
        top: 4px;
        left: 28px;
        width: $size-point;
        height: $size-point;

        border-radius: 50%;
        //noinspection SassScssResolvedByNameOnly
        background-color: $primary !important;
      }

      &-icon {
        flex: 0 0 $size-icon;
        height: $size-icon;

        //noinspection SassScssUnresolvedMixin
        @include widescreen {
          margin-right: 10px;
        }
      }

      &-text {
        //noinspection SassScssResolvedByNameOnly
        color: $menu-item-color !important;
        opacity: 1;
        transition: opacity 250ms ease-in-out 50ms;
        line-height: 1.25;
        //white-space: nowrap;
      }
    }

    &.external-item::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      border-radius: 8px;
      pointer-events: none;
      z-index: -1;
    }

    &.inner-item {
      padding-left: 3rem;

      &, .menu-item-text {
        //noinspection SassScssResolvedByNameOnly
        color: $collapse-menu-color !important;
      }

      .badges {
        margin-left: 0.44rem;
      }
    }

    &.is-active, &:hover {
      &.inner-item {
        &, .menu-item-text  {
          //noinspection SassScssResolvedByNameOnly
          color: $menu-item-color !important;
        }

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);

          width: 4px;
          height: 85%;

          border-radius: 3px 0 0 3px;
          pointer-events: none;
        }

        .menu-item-dash, &::after {
          //noinspection SassScssResolvedByNameOnly
          background-color: $menu-item-color !important;
          opacity: 1;
        }
      }
    }

    &.is-active.external-item::before {
      //noinspection SassScssResolvedByNameOnly
      background-color: $menu-item-background-color;
    }
  }
</style>
