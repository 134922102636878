<template>
    <div>
        <b-navbar
            fixed-top
            shadow>
            <template #burger="{ isOpened, toggleActive }">
                <div
                    class="burger"
                    @click="toggleActive">
                    <img
                        :src="avatarUrl"
                        class="avatar-user"
                        alt="">
                    <span class="mr-2 user-login">
                        {{ user.login }}
                    </span>
                    <b-icon :icon="`angle-${ !isOpened ? 'down' : 'up' }`"></b-icon>
                </div>
            </template>

            <template #brand>
                <div class="brand">
                    <slot name="burger"></slot>

                    <div></div>
                    <!--                    <GameInfoDropdown v-if="!asAdmin"></GameInfoDropdown>-->

                    <div class="columns">
                        <div
                            v-if="isVisibleCompetition"
                            class="column flex is-align-items-center">
                            <CompetitionDropdown></CompetitionDropdown>
                        </div>
                    </div>
                </div>
            </template>

            <template #start>
                <div class="columns is-marginless is-variable is-4">
                    <Manager v-if="role === 'webmaster' && user.hasManager || role === 'advertiser' && user.hasManager"></Manager>
                    <Manager
                        v-if="role === 'webmaster' && subType === 'SUB_WEBMASTER'"
                        show-agent>
                    </Manager>
                    <div
                        v-if="(role === 'webmaster' || role === 'advertiser')"
                        class="column">
                        <Balance :role="role"></Balance>
                    </div>

                    <!--                    <div class="dropdown-trigger mx-2">-->
                    <!--                        <LTooltip-->
                    <!--                            :label="$t('common.navBar.tooltip.feedback')"-->
                    <!--                            position="is-bottom"-->
                    <!--                            type="is-dark">-->
                    <!--                            <b-button-->
                    <!--                                type="is-success"-->
                    <!--                                tag="a"-->
                    <!--                                href="https://forms.gle/XRBJRa5CT2nABX496"-->
                    <!--                                target="_blank">-->
                    <!--                                {{ $t("common.navBar.feedback") }}-->
                    <!--                            </b-button>-->
                    <!--                        </LTooltip>-->
                    <!--                    </div>-->
                    <b-dropdown
                        v-if="role === 'admin'"
                        :mobile-modal="false"
                        append-to-body
                        class="partner"
                        position="is-bottom-left">
                        <template #trigger>
                            <button class="partner-invite">
                                {{ $t("webmaster.navBar.partners.invite") }}
                            </button>
                        </template>
                        <div class="partner-body">
                            <h2 class="partner-title">
                                {{ $t("webmaster.navBar.partners.body.title") }}
                            </h2>
                            <p class="partner-info">
                                {{ $t("webmaster.navBar.partners.body.info") }}
                            </p>
                            <b-field>
                                <div class="control is-fullwidth">
                                    <CustomInput
                                        :value="referralLink"
                                        :label="$t('webmaster.navBar.partners.body.link')"
                                        copy
                                        expanded
                                        readonly>
                                    </CustomInput>
                                </div>
                            </b-field>
                        </div>
                    </b-dropdown>
                </div>
            </template>

            <template #end>
                <SocialNetworkLinks v-if="role === 'admin' || role === 'webmaster'">
                </SocialNetworkLinks>
                <Permissions :permissions="['TRAFFIC_REQUESTS.LIST.ALL', 'TRAFFIC_REQUESTS.LIST_HANDLE.OWN']">
                    <NotificationsDropdown v-if="role === 'admin'">
                    </NotificationsDropdown>
                </Permissions>
                <b-dropdown
                    v-model="locale"
                    :mobile-modal="false"
                    append-to-body
                    aria-role="menu"
                    class="is-unselectable"
                    position="is-bottom-left"
                    @active-change="isActiveLocaleModal = $event">
                    <template #trigger>
                        <span
                            class="navbar-link navbar-item is-arrowless"
                            role="button">
                            <span class="is-uppercase is-flex is-align-items-center">
                                <!--suppress HtmlUnknownTag -->
                                <flag
                                    :iso="ISOLocale(locale)"
                                    class="media-left mr-2 is-content-box">
                                </flag>
                                {{ locale }}
                            </span>
                            <b-icon
                                :icon="`chevron-${ isActiveLocaleModal ? 'up' : 'down' }`"
                                class="ml-3">
                            </b-icon>
                        </span>
                    </template>
                    <b-dropdown-item
                        :key="locale_"
                        v-for="(locale_, index) in $root.$i18n.availableLocales"
                        :index="index"
                        :value="locale_"
                        aria-role="listitem">
                        <div class="media is-align-items-center">
                            <!--suppress HtmlUnknownTag -->
                            <flag
                                :iso="ISOLocale(locale_)"
                                class="media-left"></flag>
                            <div class="media-content">
                                <h3>{{ $t(`common.translations.${ locale_ }`) }}</h3>
                                <small>{{ $t(`common.nativeTranslationLanguages.${ locale_ }`) }}</small>
                            </div>
                        </div>
                    </b-dropdown-item>
                </b-dropdown>

                <b-dropdown
                    :mobile-modal="false"
                    append-to-body
                    aria-role="menu"
                    class="is-unselectable"
                    position="is-bottom-left"
                    @active-change="isActiveProfileModal = $event">
                    <template #trigger>
                        <span
                            class="navbar-link navbar-item is-arrowless"
                            role="button">
                            <img
                                :src="avatarUrl"
                                class="avatar-user"
                                alt="">
                            <span>{{ user.login }}</span>
                            <b-icon
                                :icon="`chevron-${ isActiveProfileModal ? 'up' : 'down' }`"
                                class="ml-3">
                            </b-icon>
                        </span>
                    </template>
                    <b-dropdown-item
                        v-if="role === 'webmaster'"
                        has-link>
                        <router-link :to="{ name: 'webmaster:profile:profile' }">
                            <div class="media is-align-items-center">
                                <b-icon
                                    class="media-left"
                                    icon="user"
                                    pack="far"></b-icon>
                                <div class="media-content">
                                    <h3>{{ $t("common.navBar.profile") }}</h3>
                                </div>
                            </div>
                        </router-link>
                    </b-dropdown-item>
                    <b-dropdown-item
                        v-if="['advertiser', 'admin'].includes(role)"
                        @click="openProfileModal">
                        <div class="media is-align-items-center">
                            <b-icon
                                class="media-left"
                                icon="user"
                                pack="far">
                            </b-icon>
                            <div class="media-content">
                                <h3>{{ $t("common.navBar.profile") }}</h3>
                            </div>
                        </div>
                    </b-dropdown-item>
                    <hr class="dropdown-divider">
                    <b-dropdown-item @click="UPDATE_MODAL_ACTIVE(true)">
                        <div class="media is-align-items-center">
                            <img
                                src="@/assets/update-photo.svg"
                                class="media-left"
                                alt="">
                            <div class="media-content">
                                <h3>{{ $t("common.navBar.updateAvatar") }}</h3>
                            </div>
                        </div>
                    </b-dropdown-item>
                    <hr class="dropdown-divider">
                    <b-dropdown-item
                        value="logout"
                        @click="logout">
                        <div class="media is-align-items-center">
                            <b-icon
                                class="media-left"
                                icon="arrow-right-from-bracket"
                                pack="fas">
                            </b-icon>
                            <div class="media-content">
                                <h3>{{ $t(`common.navBar.${ asAdmin ? 'toAdmin' : 'logout' }`) }}</h3>
                            </div>
                        </div>
                    </b-dropdown-item>
                </b-dropdown>
            </template>
        </b-navbar>
        <!--        <Lightrope></Lightrope>-->
        <AdvertiserProfileModal></AdvertiserProfileModal>
        <AdminProfileModal></AdminProfileModal>
        <UserAvatarModal></UserAvatarModal>
        <transition name="fade">
            <ChangeManagerModal v-if="isActiveChangeModal"></ChangeManagerModal>
        </transition>
        <FinanceAutoPaymentsModal v-if="role === 'webmaster'"></FinanceAutoPaymentsModal>
        <FinanceAutoPaymentsListModal v-if="role === 'webmaster'"></FinanceAutoPaymentsListModal>
        <WebmasterProfileRequisitesModal v-if="role === 'webmaster'"></WebmasterProfileRequisitesModal>
        <OfferDisclaimerModal v-if="isActiveOfferDisclaimerModal"></OfferDisclaimerModal>
    </div>
</template>

<script>
  import CompetitionDropdown from "@/components/Webmaster/Dashboard/Competition/CompetitionDropdown.vue";
  // import GameInfoDropdown from "@/components/Common/NavigationBar/Game/GameInfoDropdown.vue";
  import AdminProfileModal from "@/components/Admin/NavigationBar/AdminProfileModal";
  import AdvertiserProfileModal from "@/components/Advertiser/NavigationBar/AdvertiserProfileModal";
  import UserAvatarModal from "@/components/Common/NavigationBar/UserAvatarModal";
  import Balance from "@/components/Common/NavigationBar/Balance";
  import Manager from "@/components/Common/NavigationBar/Manager";
  import CustomInput from "@/components/Common/Select/CustomInput.vue";
  import {
    GET_ADVERTISER_BALANCE,
    SET_EMPTY_USER_PROFILE,
    GET_WEBMASTER_BALANCE,
    AUTHENTICATION_LOGOUT,
    GET_USER_PERMISSIONS,
    ADMIN_LOGOUT_ROLE,
    UPDATE_LOCALE,
    USER_LOGOUT
  } from "@core/store/action-constants";
  import { UPDATE_MODAL, UPDATE_MODAL_ACTIVE } from "@core/store/mutation-constants";
  import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
  import SocialNetworkLinks from "@/components/Common/NavigationBar/SocialNetworkLinks";
  import FinanceAutoPaymentsModal from "@/components/Webmaster/Finance/modal/FinanceAutoPaymentsModal.vue";
  import FinanceAutoPaymentsListModal from "@/components/Webmaster/Finance/modal/FinanceAutoPaymentsListModal.vue";
  import WebmasterProfileRequisitesModal
    from "@/components/Common/Webmaster/WebmasterProfileSettings/WebmasterProfileRequisites/WebmasterProfileRequisitesModal.vue";
  import ChangeManagerModal from "@/components/Common/NavigationBar/ChangeManagerModal.vue";
  import { useToChangeManagerModal } from "@/stores/webmaster/profile/profileToChangeManagerModalStore";
  import { storeToRefs } from "pinia";
  import { usePermissions } from "@/stores/common/auth/permissionsStore";
  import OfferDisclaimerModal from "@/components/Common/Offer/OfferDisclaimer/OfferDisclaimerModal.vue";
  import { offerDisclaimerModal } from "@/stores/common/offer/OfferDisclaimerModal";
  import NotificationsDropdown from "@/components/Common/NavigationBar/NotificationsDropdown.vue";
  import Permissions from "@/components/Common/Permissions.vue";

  export default {
    name: "NavigationBar",
    components: {
      // GameInfoDropdown,
      Permissions,
      NotificationsDropdown,
      OfferDisclaimerModal,
      WebmasterProfileRequisitesModal,
      FinanceAutoPaymentsListModal,
      FinanceAutoPaymentsModal,
      ChangeManagerModal,
      SocialNetworkLinks,
      Manager,
      CompetitionDropdown,
      AdvertiserProfileModal,
      UserAvatarModal,
      CustomInput,
      AdminProfileModal,
      Balance
    },

    setup () {
      const managerChangeStore = useToChangeManagerModal();
      const { isActive } = storeToRefs(managerChangeStore);

      const offerDisclaimerStore = offerDisclaimerModal();
      const { isActive: isActiveOfferDisclaimerModal } = storeToRefs(offerDisclaimerStore);

      return {
        isActiveChangeModal: isActive,
        isActiveOfferDisclaimerModal: isActiveOfferDisclaimerModal,
        permissionsStore: usePermissions()
      };
    },

    data () {
      return {
        competitionInterval: null,
        isPopupShown: false,
        isActiveLocaleModal: false,
        isActiveProfileModal: false
      };
    },

    computed: {
      isProfileModal: {
        get () {
          // У веба прфоиль не в модалке и вообще не в навбаре
          if (this.role !== "webmaster") {
            return this.$store[this.role].profile.modal.isActive;
          }
          return false;
        },

        set (isActive) {
          if (this.role !== "webmaster") {
            // Не mapMutations, т.к. у веба такой мутации нет
            this.$store.commit(`${ this.role }/profile/${ UPDATE_MODAL }`, { isActive });
          }
        }
      },

      ...mapState({
        state: state => state.auth,
        subType: ({ auth }) => auth.subType,
        telegram: ({ admin }) => admin.profile.user.contact.telegram,
        avatarUrl: ({ userAvatarModal }) => userAvatarModal.options.avatarUrl
      }),

      ...mapGetters(["user", "role", "asAdmin"]),
      ...mapGetters("competition", ["isVisibleCompetition"]),

      referralLink () {
        return `${ process.env.VUE_APP_REFERRAL_LINK }/${ this.telegram }`;
      },

      locale: {
        get () {
          return this.$store.state.locale.locale;
        },

        set (value) {
          this.UPDATE_LOCALE(value);
        }
      }
    },

    methods: {
      ...mapActions([
        GET_WEBMASTER_BALANCE,
        GET_ADVERTISER_BALANCE,
        AUTHENTICATION_LOGOUT,
        USER_LOGOUT,
        UPDATE_LOCALE,
        ADMIN_LOGOUT_ROLE,
        SET_EMPTY_USER_PROFILE
      ]),

      ...mapActions("userAvatarModal", {
        UPDATE_MODAL_ACTIVE
      }),

      ...mapMutations({ GET_USER_PERMISSIONS }),

      async logout () {
        const asAdmin = this.asAdmin;

        if (asAdmin) {
          await this.ADMIN_LOGOUT_ROLE();
          this.SET_EMPTY_USER_PROFILE();
        } else {
          await this.AUTHENTICATION_LOGOUT();
          this.USER_LOGOUT();
          this.SET_EMPTY_USER_PROFILE();
        }
        this.GET_USER_PERMISSIONS(null);
        this.permissionsStore.$reset();
      },

      /**
       *
       * @param locale: string
       * @return { string }
       * @constructor
       */
      ISOLocale (locale) {
        if (locale === "dev") {
          return "eu";
        } else if (locale === "en") {
          return "us";
        } else {
          return locale;
        }
      },

      openProfileModal () {
        this.isProfileModal = true;
      }
    }
  };
</script>

<style lang="scss">
@import "../../../scss/colors";

.partner {
  $self: &;


  display: flex;

  .dropdown-menu {
    width: 22rem;
  }

  &-body {
    padding: .8rem;

    #{ $self }-title {
      margin-bottom: 0.5rem;
      font-weight: 700;
      font-size: 1.03em;
      color: #212121;
    }

    #{ $self }-info {
      font-size: 0.83em;
      line-height: 1.1rem;
      margin-bottom: 0.8rem;
    }
  }

  &-invite {
    height: 35px;
    margin: 0 1em;
    padding: 0 .6rem;
    font-family: inherit;
    color: #ffffff;
    text-align: center;
    border-radius: 5px;
    background: $info;
    border: none;
    outline: none;
    cursor: pointer;
  }
}

.navbar {
  $self: &;

  .columns {
    margin-top: 0 !important;
    margin-bottom: 0 !important;

    .navbar-divider {
      width: 1px;
      background-color: $light;
      height: 40px;
      margin: 1rem 0;
    }

    .column {
      position: relative;

      //&:nth-child(2) {
      //    &:not(:last-child) {
      //        &:after {
      //            content: "";
      //            display: block;
      //            position: absolute;
      //
      //            top: 0;
      //            right: 0;
      //            bottom: 0;
      //            width: 1px;
      //            height: 60%;
      //            margin: auto;
      //            background-color: #ececec;
      //
      //            //noinspection SassScssUnresolvedMixin
      //            @include touch {
      //                display: none;
      //            }
      //        }
      //    }
      //}

      &.is-flex {
        align-items: center;
      }
    }
  }

  &-brand {
    //noinspection SassScssUnresolvedMixin
    @include desktop {
      flex-grow: 1 !important;
    }

    justify-content: flex-end;
  }

  &-burger {
    margin-left: 0 !important;
  }

  &-menu {
    //noinspection SassScssUnresolvedMixin
    @include desktop {
      flex-grow: 0 !important;
    }

    #{ $self }-end {
      text-align: right;
    }
  }
}

.avatar-user {
  $bigSize: 36px;
  $border-color: #E1E1E1;

  width:  $bigSize;
  height: $bigSize;
  max-height:  $bigSize !important;

  margin-right: 0.625rem;
  border-radius: 50%;
  border: 1px solid $border-color;
}

body {
  &.is-mini .navbar {
    //noinspection SassScssResolvedByNameOnly
    padding-left: $aside-width-until-widescreen !important;
  }
  .navbar {
    //noinspection SassScssResolvedByNameOnly
    padding-left: $aside-width-widescreen !important;
  }
}

.buttonWrapper {
  display: flex;
  align-items: center;
  padding: 10px 0;

  .dropdown {
    margin-right: .5rem;
  }
}

.tabletStyle {
  // noinspection SassScssUnresolvedMixin
  @include touch {
    .buttonWrapper {
      flex-direction: inherit;
      justify-content: flex-end !important;
      padding: 10px;
    }
    .balance-refill {
      margin-bottom: 0 !important;
    }
  }
}

.navbar-brand {
  .columns {
    display: flex;
  }
}

.dropdown-trigger {
  margin: auto 0;
}
</style>

<style lang="scss" scoped>
::v-deep {
  .navbar.is-fixed-top {
    z-index: 35;
  }
}

.navbar {
  $self: &;

  ::v-deep {
    #{ $self }-menu.is-active {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: stretch;
      }
    }

    #{ $self }-start {
      .columns {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
}

.brand {
  display: flex;
  justify-content: space-between;

  width: 100%;

  ::v-deep {
    .aside-menu-button {
      // noinspection SassScssUnresolvedMixin
      @include desktop {
        display: none;
      }
    }
  }
}

.burger {
  display: flex;
  align-items: center;

  padding: 0 1.5rem 0 1rem;
  cursor: pointer;

  //noinspection SassScssUnresolvedMixin
  @include desktop {
    display: none;
  }
}

@media screen and (max-width: 1023px) {
  .user-login {
    display: none;
  }
}
</style>
