import Service from "@core/services/common/Service";
import { OffersFilters, OffersState } from "@core/store/types/admin/offers";
import offers from "./graphql/offers.generator";
import advertisersCapacityQuery from "@core/services/admin/offers/graphql/advertisersCapacity.generator";
import advertiserCapacityHistoryQuery from "@core/services/admin/offers/graphql/advertiserCapacityHistory.graphql";
import userOfferCapacityHistoryQuery from "@core/services/admin/offers/graphql/userOfferCapacityHistory.graphql";
import updateOfferWebmasterRequiredApproveMutation from "@core/services/admin/offers/graphql/updateOfferWebmasterRequiredApprove.graphql";
import webmastersCapacityAnalyseQuery
  from "@core/services/admin/offers/graphql/webmastersCapacityAnalyse.generator";
import offerAdvertisersCapacityQuery from "@core/services/admin/offers/graphql/offerAdvertisersCapacity.generator";
import offerWebmastersCapacityQuery from "@core/services/admin/offers/graphql/offerWebmastersCapacity.generator";
import externalWebmasterCapacityQuery from "@core/services/admin/offers/graphql/externalWebmasterCapacity.generator";
import subWebmasterCapacityQuery from "@core/services/admin/offers/graphql/subWebmasterCapacity.generator";
import setCapacityQuery from "@core/services/admin/offers/graphql/setCapacity.graphql";
import {
  AdvertisersCapacityFilters,
  AdvertisersCapacityHistoryData,
  AdvertisersCapacityState,
  CapacityAnalyseFilters,
  CapacityAnalyseItem,
  InputCapacity,
  OffersCapacityResponse,
  OffersCapacityState,
  SubWebmastersCapacityState,
  WebmastersCapacityResponse,
  WebmastersCapacityState,
  InputApprove,
  UpdateApproveResponse, AdvertisersCapacityExcludedFilters
} from "@core/store/types/admin/offers/capacity";
import { capacityMapper } from "@core/services/admin/offers/capacityMapper";
import { SetCapacityDTO } from "@core/services/admin/offers/setCapacityDTO";
import { CapacityGroupsEnum } from "@core/store/types/admin/capacity/enums/CapacityGroupsEnum";
import { prepareExcludedFilters } from "@core/helpers/prepareExcludedFilters";
import agentCapacityAnalyseQuery from "@core/services/admin/offers/graphql/agentCapacityAnalyse.generator";

export class OffersService extends Service {
  public static async getOffers (
    filters: OffersFilters,
    excludedFilters: OffersFilters,
    limit: number,
    offset: number,
    sort: OffersState["sort"],
    order: OffersState["order"],
    isShowCommissions: boolean,
    showAdvertisers: boolean,
    showIndividualRates: boolean
  ): Promise<OffersState["data"]> {
    const { data: { offers: data } } = await this.api.get("", {
      params: offers(
        filters,
        prepareExcludedFilters(excludedFilters),
        limit,
        offset,
        sort,
        order,
        isShowCommissions,
        showAdvertisers,
        showIndividualRates
      )
    });
    return data;
  }
  
  public static async getAdvertisersCapacity (
    limit: number,
    offset: number,
    filters: Partial<AdvertisersCapacityFilters>,
    excludedFilters: AdvertisersCapacityExcludedFilters,
    sort: AdvertisersCapacityState["sort"],
    order: AdvertisersCapacityState["order"],
    isRequest: boolean,
    isCommon: boolean,
    isShowRedemption: boolean
  ): Promise<AdvertisersCapacityState["data"]> {
    try {
      const { data: { advertisersCapacity } } = await this.api.get("", {
        params: advertisersCapacityQuery(
          limit,
          offset,
          filters,
          prepareExcludedFilters(excludedFilters),
          sort,
          order,
          isRequest,
          isCommon,
          isShowRedemption
        )
      });
      
      return capacityMapper(advertisersCapacity, CapacityGroupsEnum.ADVERTISER);
    }
    catch (e) {
      throw e;
    }
  }
  
  public static async getUserCapacityHistory (
    limit: number,
    offset: number,
    userId: string | null,
    offerId: string | null
  ): Promise<AdvertisersCapacityHistoryData> {
    try {
      if (offerId) {
        const { data: { userOfferCapacityHistory } } = await this.api.get("", {
          params: {
            query: userOfferCapacityHistoryQuery,
            variables: {
              limit,
              offset,
              userId,
              offerId
            }
          }
        });
        return userOfferCapacityHistory;
      }

      const { data: { advertiserCapacityHistory } } = await this.api.get("", {
        params: {
          query: advertiserCapacityHistoryQuery,
          variables: {
            limit,
            offset,
            userId
          }
        }
      });

      return advertiserCapacityHistory;
    }
    catch (e) {
      throw e;
    }
  }
  
  public static async getWebmastersCapacityAnalyse (
    offerId: string,
    webmasterId: string,
    filters: CapacityAnalyseFilters,
    isShowRedemption: boolean
  ): Promise<CapacityAnalyseItem[]> {
    try {
      const { data: { capacityGraphByWebmaster, capacityGraphBySubWebmaster } } = await this.api.get("", {
        params: webmastersCapacityAnalyseQuery(
          offerId,
          webmasterId,
          filters,
          isShowRedemption
        )
      });

      return capacityGraphByWebmaster ?? capacityGraphBySubWebmaster;
    }
    catch (e) {
      throw e;
    }
  }

  public static async getAgentCapacityAnalyse (
    offerId: string,
    agentId: string,
    filters: CapacityAnalyseFilters,
    isShowRedemption: boolean
  ): Promise<CapacityAnalyseItem[]> {
    try {
      const { data: { capacityGraphByAgent } } = await this.api.get("", {
        params: agentCapacityAnalyseQuery(
          offerId,
          agentId,
          filters,
          isShowRedemption
        )
      });

      return capacityGraphByAgent;
    }
    catch (e) {
      throw e;
    }
  }

  public static async getOffersCapacity (
    limit: number,
    offset: number,
    advertiserId: string,
    filters: Partial<AdvertisersCapacityFilters>,
    sort: OffersCapacityState["sort"],
    order: OffersCapacityState["order"],
    isRequest: boolean,
    isShowRedemption: boolean,
    isCommon?: boolean
  ): Promise<OffersCapacityResponse> {
    try {
      const { data: { offerAdvertisersCapacity } } = await this.api.get("", {
        params: offerAdvertisersCapacityQuery(
          limit,
          offset,
          advertiserId,
          filters,
          sort,
          order,
          isRequest,
          isShowRedemption,
          isCommon
        )
      });

      return capacityMapper(offerAdvertisersCapacity, CapacityGroupsEnum.OFFER);
    }
    catch (e) {
      throw e;
    }
  }
  
  public static async getWebmastersCapacity (
    limit: number,
    offset: number,
    offerId: string,
    filters: Pick<Partial<AdvertisersCapacityFilters>, "id" | "managerId">,
    sort: WebmastersCapacityState["sort"],
    order: WebmastersCapacityState["order"],
    isRequest: boolean,
    isIndividualRate: boolean,
    isShowRedemption: boolean,
    isShowCommission: boolean
  ): Promise<WebmastersCapacityResponse> {
    try {
      const { data: { offerWebmastersCapacity } } = await this.api.get("", {
        params: offerWebmastersCapacityQuery(
          limit,
          offset,
          offerId,
          filters,
          sort,
          order,
          isRequest,
          isIndividualRate,
          isShowRedemption,
          isShowCommission
        )
      });
      
      return capacityMapper(offerWebmastersCapacity, CapacityGroupsEnum.WEBMASTER);
    }
    catch (e) {
      throw e;
    }
  }
  
  public static async getExternalWebmastersCapacity (
    limit: number,
    offset: number,
    webmasterId: string,
    offerId: string,
    sort: WebmastersCapacityState["sort"],
    order: WebmastersCapacityState["order"],
    isRequest: boolean,
    isShowRedemption: boolean,
    isIndividualRate: boolean,
    isShowCommission: boolean
  ): Promise<WebmastersCapacityResponse> {
    try {
      const { data: { externalWebmasterCapacity } } = await this.api.get("", {
        params: externalWebmasterCapacityQuery(
          limit,
          offset,
          webmasterId,
          offerId,
          sort,
          order,
          isRequest,
          isIndividualRate,
          isShowRedemption,
          isShowCommission
        )
      });
      return capacityMapper(externalWebmasterCapacity, CapacityGroupsEnum.EXTERNAL_WEBMASTER);
    }
    catch (e) {
      throw e;
    }
  }

  public static async getSubWebmastersCapacity (
    limit: number,
    offset: number,
    agentId: string,
    offerId: string,
    sort: SubWebmastersCapacityState["sort"],
    order: SubWebmastersCapacityState["order"],
    filters: SubWebmastersCapacityState["filters"],
    isIndividualRate: boolean,
    isShowRedemption: boolean,
    isShowCommission: boolean
  ) {
    try {
      const { data: { subWebmastersCapacity } } = await this.api.get("", {
        params: subWebmasterCapacityQuery(
          limit,
          offset,
          agentId,
          offerId,
          filters,
          sort,
          order,
          isIndividualRate,
          isShowRedemption,
          isShowCommission
        )
      });

      return capacityMapper(subWebmastersCapacity, CapacityGroupsEnum.SUB_WEBMASTER);
    }
    catch (e) {
      throw e;
    }
  }

  public static async updateCapacity (
    input: Partial<InputCapacity>
  ) {
    try {
      const { data: { setCapacity } } = await this.api.post("", {
        query: setCapacityQuery,
        variables: {
          input
        }
      });

      return new SetCapacityDTO(setCapacity);
    }
    catch (e) {
      throw e;
    }
  }

  public static async updateApprove (
    input: InputApprove
  ): Promise<UpdateApproveResponse> {
    try {
      const { data: { updateOfferWebmasterRequiredApprove } } = await this.api.post("", {
        query: updateOfferWebmasterRequiredApproveMutation,
        variables: {
          input
        }
      });
      return updateOfferWebmasterRequiredApprove;
    }
    catch (e) {
      throw e;
    }
  }
}
