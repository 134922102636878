<template>
    <div class="background">
        <div class="lemons">
            <div class="lemons-left">
                <div class="lemon left-1">
                    <img
                        src="~@/assets/Auth/lemon-left-1.png"
                        alt="lemon-image">
                </div>
                <div class="lemon left-2">
                    <img
                        src="~@/assets/Auth/lemon-left-2.png"
                        alt="lemon-image">
                </div>
                <div class="lemon left-3">
                    <img
                        src="~@/assets/Auth/lemon-left-3.png"
                        alt="lemon-image">
                </div>
                <div class="lemon left-4">
                    <img
                        src="~@/assets/Auth/lemon-left-4.png"
                        alt="lemon-image">
                </div>
                <div class="lemon left-5">
                    <img
                        src="~@/assets/Auth/lemon-left-5.png"
                        alt="lemon-image">
                </div>
                <div class="lemon left-6">
                    <img
                        src="~@/assets/Auth/lemon-left-6.png"
                        alt="lemon-image">
                </div>
                <div class="lemon left-7">
                    <img
                        src="~@/assets/Auth/lemon-left-7.png"
                        alt="lemon-image">
                </div>
                <div class="line line-left-1">
                    <img
                        src="~@/assets/Auth/line-left-1.png"
                        alt="line-image">
                </div>
                <div class="line line-left-2">
                    <img
                        src="~@/assets/Auth/line-left-2.png"
                        alt="line-image">
                </div>
                <div class="line line-left-3">
                    <img
                        src="~@/assets/Auth/line-left-3.png"
                        alt="line-image">
                </div>
                <div class="line line-left-4">
                    <img
                        src="~@/assets/Auth/line-left-4.png"
                        alt="line-image">
                </div>
            </div>
            <div class="lemons-right">
                <div class="lemon right-1">
                    <img
                        src="~@/assets/Auth/lemon-right-1.png"
                        alt="lemon-image">
                </div>
                <div class="lemon right-2">
                    <img
                        src="~@/assets/Auth/lemon-right-2.png"
                        alt="lemon-image">
                </div>
                <div class="lemon right-3">
                    <img
                        src="~@/assets/Auth/lemon-right-3.png"
                        alt="lemon-image">
                </div>
                <div class="lemon right-4">
                    <img
                        src="~@/assets/Auth/lemon-right-4.png"
                        alt="lemon-image">
                </div>
                <div class="lemon right-5">
                    <img
                        src="~@/assets/Auth/lemon-right-5.png"
                        alt="lemon-image">
                </div>
                <div class="lemon right-6">
                    <img
                        src="~@/assets/Auth/lemon-right-6.png"
                        alt="lemon-image">
                </div>
                <div class="lemon right-7">
                    <img
                        src="~@/assets/Auth/lemon-right-7.png"
                        alt="lemon-image">
                </div>
                <div class="line line-right-1">
                    <img
                        src="~@/assets/Auth/line-right-1.png"
                        alt="line-image">
                </div>
                <div class="line line-right-2">
                    <img
                        src="~@/assets/Auth/line-right-2.png"
                        alt="line-image">
                </div>
                <div class="line line-right-3">
                    <img
                        src="~@/assets/Auth/line-right-3.png"
                        alt="line-image">
                </div>
                <div class="line line-right-4">
                    <img
                        src="~@/assets/Auth/line-right-4.png"
                        alt="line-image">
                </div>
                <div class="line line-right-5">
                    <img
                        src="~@/assets/Auth/line-right-5.png"
                        alt="line-image">
                </div>
                <div class="line line-right-6">
                    <img
                        src="~@/assets/Auth/line-right-6.png"
                        alt="line-image">
                </div>
            </div>
        </div>
        <slot></slot>
    </div>
</template>

<script>
  export default {
    name: "Background"
  };
</script>

<style scoped lang="scss">
  @mixin lemon ($top, $left, $right, $width) {
    top: $top;
    left: $left;
    right: $right;
    z-index: -2;

    img {
      width: $width;
    }

    @media screen and (max-width: 1024px) {
      width: $width * 1.8;

      img {
        width: $width * 1.8;
      }
    }

    @media screen and (max-width: 425px) {
      width: $width * 2;

      img {
        width: $width * 2;
      }
    }
  }

  .lemons {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;

    .lemon {
      position: absolute;
    }

    .line{
      position: absolute;
    }

    .left-1 {
      @include lemon($top: 7vh, $left: 0vw, $right: auto, $width: 10vw);
      z-index: 0;
    }

    .left-2 {
      @include lemon($top: 14vh, $left: 0vw, $right: auto, $width: 15vw);
      z-index: -1;
    }

    .left-3 {
      @include lemon($top: 14vh, $left: 8vw, $right: auto, $width: 10vw);
    }

    .left-4 {
      @include lemon($top: 25vh, $left: 0vw, $right: auto, $width: 18vw);
      z-index: -1;
    }

    .left-5 {
      @include lemon($top: 40vh, $left: 0vw, $right: auto, $width: 10vw);
      z-index: -3;
    }

    .left-6 {
      @include lemon($top: 39vh, $left: 2vw, $right: auto, $width: 15vw);
      z-index: -2;
    }

    .left-7 {
      @include lemon($top: 51vh, $left: 4vw, $right: auto, $width: 15vw);
    }

    .line-left-1 {
      @include lemon($top: 28vh, $left: 1.5vw, $right: auto, $width: 0.1vw);
      z-index: -5;
    }

    .line-left-2 {
      @include lemon($top: 18vh, $left: 7vw, $right: auto, $width: 0.1vw);
    }

    .line-left-3 {
      @include lemon($top: 50vh, $left: 9vw, $right: auto, $width: 0.2vw);
    }

    .line-left-4 {
      @include lemon($top: 38vh, $left: 8.9vw, $right: auto, $width: 0.1vw);
    }

    .right-1 {
      @include lemon ($top: 28vh, $left: auto, $right: 5vw, $width: 10vw);
      z-index: -1;
    }

    .right-2 {
      @include lemon ($top: 36vh, $left: auto, $right: -3.6vw, $width: 13vw);
      z-index: -2;
    }

    .right-3 {
      @include lemon ($top: 45vh, $left: auto, $right: 2vw, $width: 11vw);
      z-index: -1;
    }

    .right-4 {
      @include lemon ($top: 50vh, $left: auto, $right: 0vw, $width: 18vw);
      z-index: 0;
    }

    .right-5 {
      @include lemon ($top: 72vh, $left: auto, $right: 8.5vw, $width: 10vw);
    }

    .right-6 {
      @include lemon ($top: 66vh, $left: auto, $right: 0vw, $width: 14vw)
    }

    .right-7 {
      @include lemon ($top: 45vh, $left: auto, $right: -3.5vw, $width: 12vw)
    }

    .line-right-1 {
      @include lemon($top: 53vh, $left: auto, $right: 12vw, $width: 0.25vw);
      z-index: -1;
    }

    .line-right-2 {
      @include lemon($top: 39vh, $left: auto, $right: 10vw, $width: 0.15vw);
    }

    .line-right-3 {
      @include lemon($top: 33vh, $left: auto, $right: 9.9vw, $width: 0.1vw);
      z-index: -1;
    }

    .line-right-4 {
      @include lemon($top: 54vh, $left: auto, $right: 8vw, $width: 0.1vw);
      z-index: -3;
    }

    .line-right-5 {
      @include lemon($top: 29vh, $left: auto, $right: 2.5vw, $width: 0.7vw);
      z-index: 0;
    }

    .line-right-6 {
      @include lemon($top: 42vh, $left: auto, $right: 1.5vw, $width: 0.1vw);
      z-index: -3;
    }

    @media screen and (max-width: 1024px) {
      .lemons-right {
        position: relative;
        right: -3vw;
      }
    }
    //
    //@media screen and (max-width: 425px) {
    //  .lemons-left {
    //    position: relative;
    //    right: 20vh;
    //  }
    //}
  }
</style>
