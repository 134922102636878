import Oauth2 from "@/components/Common/Auth/Oauth2.vue";
import Empty from "@/views/Empty.vue";

export default {
  path: "oauth2",
  name: "oauth2",
  component: Empty,
  children: [
    {
      name: "authorize",
      path: "authorize",
      component: Oauth2
    }
  ]
};